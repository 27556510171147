import * as React from "react"
import cx from "classnames"

import styles from "./form.module.scss"

interface FormContainerProps {
  children: React.ReactNode
  className?: string
}

export const FormContainer = ({ children, className }: FormContainerProps) => (
  <div className={cx(styles.formContainer, className)}>{children}</div>
)

interface FieldProps {
  label?: string
  name?: string
  isWide?: boolean
  isHidden?: boolean
  isRequired?: boolean
  align?: "left" | "right"
  errors?: string
  info?: string
  className?: string
  children?: React.ReactNode
}

export function Field({
  label,
  name,
  isWide,
  isHidden,
  isRequired,
  align,
  errors,
  info,
  className,
  children,
}: FieldProps) {
  return (
    <div
      className={cx(styles.field, className, styles[`${align}`], {
        [styles.hasError]: errors,
        [styles.isWide]: isWide,
        [styles.isHidden]: isHidden,
      })}
    >
      {label && (
        <label htmlFor={name} className={styles.fieldLabel}>
          {label}
          {isRequired && <span className={styles.fieldLabelAsterix}>*</span>}
        </label>
      )}
      {children}
      {errors && (
        <div className={styles.fieldError} role="alert">
          {errors}
        </div>
      )}
      {info && <div className={styles.infoText}>{info}</div>}
    </div>
  )
}

interface TextFieldProps {
  isRequired?: boolean
  isWide?: boolean
  isHidden?: boolean
  align?: "left" | "right"
  type?: string
  className?: string
  label?: string
  name: string
  placeholder?: string
  errors?: string
}

export const TextField = React.forwardRef<HTMLDivElement, TextFieldProps>(
  (
    {
      isRequired = false,
      isWide = false,
      isHidden = false,
      align,
      type = "text",
      className,
      label,
      name,
      placeholder,
      errors,
      ...props
    },
    ref
  ) => {
    const InputElement: HTMLInputElement | string =
      type === "textarea" ? "textarea" : "input"

    return (
      <Field
        className={className}
        label={label}
        name={name}
        isWide={isWide}
        isHidden={isHidden}
        isRequired={isRequired}
        align={align}
        errors={errors}
      >
        <InputElement
          {...props}
          // eslint-disable-next-line
          // eslint-disable-next-line @typescript-eslint/<type of error>
          placeholder={placeholder}
          name={name}
          type={type}
          aria-invalid={errors ? "true" : "false"}
          className={styles.textField}
          ref={ref}
        />
      </Field>
    )
  }
)
interface SelectFieldProps {
  isRequired?: boolean
  isWide?: boolean
  className?: string
  label?: string
  name: string
  placeholder?: string
  errors?: string
  options: Array<string>
}

export const SelectField = React.forwardRef<HTMLDivElement, SelectFieldProps>(
  (
    {
      isRequired = false,
      isWide = false,
      className,
      label,
      name,
      placeholder,
      errors,
      options = [],
      ...props
    },
    ref
  ) => {
    return (
      <Field
        className={className}
        label={label}
        name={name}
        isWide={isWide}
        errors={errors}
      >
        <select
          {...props}
          // eslint-disable-next-line
          // eslint-disable-next-line @typescript-eslint/<type of error>
          name={name}
          aria-invalid={errors ? "true" : "false"}
          className={styles.textField}
          defaultValue={""}
          ref={ref}
        >
          <option value="" disabled>
            {placeholder}
          </option>
          {options.map(option => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </Field>
    )
  }
)
